.about {
  padding: 0;
  background: $dark;
  color: $white;

  > .container-fluid {
    padding-left: 0;
    padding-right: 0;

    > .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .image-container {
    padding-left: 0;
    padding-right: 0;

    .about-image {
      height: 100%;
      margin-right: 20px;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(../images/bg-1.jpg);
    }
  }

  .about-content {
    color: $white;

    .subtitle {
      color: $orange;
    }

    .title {
      margin-top: 15px;
    }

    .values {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 0;

      li {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: $btn-border-radius;
        list-style: none;
        transition: $transition-base;

        padding: 10px 10px 10px 80px;
        padding-left: 60px;

        &:hover {
          background: $primary;
        }

        i {
          top: 0;
          left: 15px;
          display: block;
          position: absolute;
          font-size: 2rem;
        }
      }
    }
  }
}

// Variables
$enable-shadows: true;
$enable-gradients: false;
$enable-negative-margins: true;

// Fonts
$font-family-sans-serif: "Inter", sans-serif;
$font-family-monospace: "Roboto Mono", monospace;

$font-weight-lighter: lighter;
$font-weight-light: 100;
$font-weight-normal: 200;
$font-weight-bold: 400;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$display-font-weight: $font-weight-normal;
$lead-font-weight: $font-weight-normal;
$headings-font-weight: $font-weight-normal;

// Grid
//$grid-breakpoints: (
//  xs: 0,
//  sm: 576px,
//  md: 768px,
//  lg: 1200px,
//  xl: 1600px
//);
//
//$container-max-widths: (
//  sm: 540px,
//  md: 720px,
//  lg: 1170px,
//  xl: 1300px
//);

// Colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #790dbf;
$pink: #ea0599;
$red: #dc3545;
$orange: #fe9f18;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$primary: $purple;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$body-bg: $gray-100;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
) !default;

// Links
$link-color: $pink;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: none;

// Buttons
$btn-border-radius: 1rem 0;
$btn-border-radius-sm: 1rem 0;
$btn-border-radius-lg: 1rem 0;
$btn-border-radius-inverted: 0 1rem;

$btn-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

// Cards
$card-border-width: 0;
$card-border-radius: 0;

// Navigation
$navbar-nav-link-padding-x: 1.5rem;

$navbar-brand-height: 10rem;
$navbar-brand-margin-end: 3rem;

$navbar-light-color: rgba($black, 1);
$navbar-light-hover-color: rgba($black, .7);
$navbar-light-active-color: rgba($black, 1);
$navbar-light-disabled-color: rgba($black, 1);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, .1);

$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, 0.8);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, .25);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: rgba($white, .1);

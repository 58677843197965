.services {
  text-align: center;
  margin-top: -20rem;

  .service-box {
    background: $white;
    position: relative;
    z-index: 50;
    padding: 1rem 2rem;
    border: 1px solid $gray-400;
    border-radius: 1rem 0;
    box-shadow: $box-shadow-sm;
    transition: $transition-base;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-content: space-between;

    &:hover {
      transform: translate(0, -10px);
      position: relative;
      z-index: 70;
      box-shadow: $box-shadow;
    }

    .icon {
      color: $primary;

      svg {
        height: 40px;
        width: 40px;
      }
    }

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: 300;

      a {
        color: $black;
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 1rem;
    }

    .btn {
      margin-top: auto;
    }
  }
}

header {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .navbar-brand {
    img {
      height: 100px;
    }
  }
}

.hero {
  height: 75vh;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 75vh;
    min-height: 400px;
    content: "";
    background: rgba(0, 0, 0, 0.5);
  }

  .caption {
    z-index: 20;
    position: relative;

    .row {
      height: 75vh;
      min-height: 400px;
    }
  }

  &.fullscreen {
    height: 100vh;

    &:after, .caption .row {
      height: 100vh;
    }

    .row {
      align-items: center;
    }
  }

  &+.services {
    margin-top: -3rem;
  }
}

$first: $purple;
$second: $orange;
$third: $pink;

.timeline-container {
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;

  .timeline {
    position: relative;
    margin-top: -135px;
    height: 270px;
    display: flex;
    flex-direction: column;

    &:after, &:before {
      content: "";
      display: block;
      width: 100%;
      clear: both;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2
    }

    &:first-child {
      margin-top: 0;
    }

    .timeline-icon, .icon {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0
    }

    .timeline-icon {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 5px solid transparent;
      border-top-color: $first;
      border-right-color: $first;
      z-index: 1;
      transform: rotate(45deg);

      .icon {
        display: block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .7);
        font-size: 30px;
        font-weight: 700;
        color: $first;
        text-align: center;
        transform: rotate(-45deg);
      }
    }

    .timeline-content {
      width: calc(50% - 140px);
      float: right;
      border: 1px solid $first;
      border-top: 5px solid $first;
      border-radius: $btn-border-radius-inverted;
      padding: 2rem 2rem;
      margin: 130px 0 0 0;
      z-index: 1;
      position: relative;
      align-self: flex-end;
      background: $white;

      &:before {
        content: "";
        width: 100%;
        height: 5px;
        background: $first;
        position: absolute;
        left: -70px;
        z-index: -1;
        top: -5px;
      }

      .title {

      }

      .description {
        margin: 0;
      }
    }

    &:nth-child(2n) {
      .timeline-icon {
        transform: rotate(-135deg);
        border-top-color: $second;
        border-right-color: $second;

        .icon {
          transform: rotate(135deg);
          color: $second;
        }
      }

      .timeline-content {
        float: left;
        align-self: flex-start;
        border-radius: $btn-border-radius;
        border-color: $second;

        &:before {
          left: auto;
          right: -70px;
          background: $second;
        }
      }
    }

    &:nth-child(3n) {
      .timeline-icon {
        border-top-color: $third;
        border-right-color: $third;

        .icon {
          color: $third;
        }
      }

      .timeline-content {
        border-color: $third;

        &:before {
          background: $third;
        }
      }
    }
  }
}

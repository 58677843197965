.products {
  .product {
    background: $white;
    box-shadow: $box-shadow-sm;

    .progress {
      margin: -1.5rem;
      background: none;
      box-shadow: none;
      margin-left: -2.2rem;
      width: calc(100% + 4.4rem);
      max-width: none;

      .progress-bar {
        width: 10%;
      }

      &.rtl {
        transform: rotateY(180deg);
      }

      &.pink {
        .progress-bar {
          background-color: $pink;
        }
      }

      &.orange {
        .progress-bar {
          background-color: $orange;
        }
      }
    }

    .icons {
      font-size: 2rem;
    }

    &:hover {
      .progress-bar {
        width: 90%;
        transition-duration: 2s;
      }
    }

  }
}
